<template>
  <div class="pa-6">
    <v-card elevation="2">
      <v-menu offset-y :close-on-content-click="false">
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" class="ml-2 my-2" depressed color="primary" :loading="categorysState.isLoading">
            <span>Создать</span>
            <v-icon class="ml-2" small>mdi-plus-circle-outline</v-icon>
          </v-btn>

          <v-btn class="ml-4 my-2 mr-2" depressed color="primary" @click="isUploadOpen = true">
            <span>Загрузить</span>
            <v-icon class="ml-2" small>mdi-cloud-upload-outline</v-icon>
          </v-btn>
        </template>
        <v-sheet max-width="750">
          <v-expansion-panels accordion>
            <v-expansion-panel v-for="category in categorys" :key="'categorys_'+category.id" :class="category._show ? '' : 'd-none'">
              <v-expansion-panel-header class="font-weight-medium">{{ category.name }}</v-expansion-panel-header>
              <v-expansion-panel-content>
                <div v-if="category.medRecordTemplateCategorys.length===0" class="text-center caption pa-1">Не найдено</div>
                <v-expansion-panels v-if="category.medRecordTemplateCategorys.length>1" accordion class="expansionPanelContentExpanded">
                  <v-expansion-panel v-for="medRecordTemplateCategory in category.medRecordTemplateCategorys" :key="'categorys1_'+medRecordTemplateCategory.id">
                    <v-expansion-panel-header class="font-weight-medium">{{ medRecordTemplateCategory.name }}</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-list>
                        <v-list-item v-for="medRecordTemplate in medRecordTemplateCategory.medRecordTemplates" :key="'medRecordTemplate_'+medRecordTemplate.id" @click="medRecordCreate(medRecordTemplate.id)">
                          <v-list-item-title class="body-2 pl-2">{{ medRecordTemplate.name }}</v-list-item-title>
                        </v-list-item>
                        <div v-if="medRecordTemplateCategory.medRecordTemplates.length===0" class="text-center caption pa-1">Не найдено</div>
                      </v-list>
                    </v-expansion-panel-content>
                  </v-expansion-panel>
                </v-expansion-panels>

                <template v-else>
                  <template v-for="medRecordTemplateCategory in category.medRecordTemplateCategorys" >
                      <v-list v-if="medRecordTemplateCategory.medRecordTemplates" :key="'categorys1_'+medRecordTemplateCategory.id">
                        <v-list-item v-for="medRecordTemplate in medRecordTemplateCategory.medRecordTemplates" :key="'medRecordTemplate_'+medRecordTemplate.id" @click="medRecordCreate(medRecordTemplate.id)">
                          <v-list-item-title class="body-2 pl-2">{{ medRecordTemplate.name }}</v-list-item-title>
                        </v-list-item>
                        <div v-if="medRecordTemplateCategory.medRecordTemplates.length===0" class="text-center caption pa-1">Не найдено</div>
                      </v-list>               
                  </template>
                </template>

              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-sheet>
      </v-menu>
     


      <v-menu offset-y :close-on-content-click="false">
        <template v-slot:activator="{ on, attrs }">
          <v-btn v-bind="attrs" v-on="on" class="ml-2 my-2" depressed color="primary" :loading="categorysState.isLoading || mediaCreateState.isLoading">
            <span>Создать из документа</span>
            <v-icon class="ml-2" small>mdi-plus-circle-outline</v-icon>
          </v-btn>
        </template>
        <v-sheet max-width="750">
          <v-expansion-panels accordion>
            <v-expansion-panel v-for="category in mediaTemplateCategories" :key="'categorys_'+category.id" :class="category._show ? '' : 'd-none'">
              <v-expansion-panel-header class="font-weight-medium">{{ category.name }}</v-expansion-panel-header>
              <v-expansion-panel-content>
                <div v-if="category.subcategories.length===0&&category.mediaTemplates.length===0" class="text-center caption pa-1">Не найдено</div>
                <v-expansion-panels accordion class="expansionPanelContentExpanded">
                  <v-expansion-panel v-for="subcategory in category.subcategories" :key="'categorys1_'+subcategory.id">
                    <v-expansion-panel-header class="font-weight-medium">{{ subcategory.name }}</v-expansion-panel-header>
                    <v-expansion-panel-content>
                      <v-list>
                        <v-list-item v-for="mediaTemplate in subcategory.mediaTemplates" :key="'mediaTemplate_'+mediaTemplate.id" @click="mediaCreateAction(mediaTemplate.id)">
                          <v-list-item-title class="body-2 pl-2">{{ mediaTemplate.name }}</v-list-item-title>
                        </v-list-item>
                        <div v-if="typeof subcategory.mediaTemplates !=='undefined'&&subcategory.mediaTemplates.length===0" class="text-center caption pa-1">Не найдено</div>
                      </v-list>
                    </v-expansion-panel-content>

                  </v-expansion-panel>
                </v-expansion-panels>
                   <v-list v-if="category.mediaTemplates.length!==0">
                      <v-list-item v-for="mediaTemplate in category.mediaTemplates" :key="'mediaTemplate_'+mediaTemplate.id" @click="mediaCreateAction(mediaTemplate.id)">
                        <v-list-item-title class="body-2 pl-2">{{ mediaTemplate.name }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
              </v-expansion-panel-content>           
            </v-expansion-panel>
          </v-expansion-panels>
          <v-list v-if="mediaTemplates.length>0">
            <v-list-item v-for="mediaTemplate in mediaTemplates" :key="'mediaTemplate_'+mediaTemplate.id" @click="mediaCreateAction(mediaTemplate.id)">
              <v-list-item-title class="body-2 pl-2">{{ mediaTemplate.name }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-sheet>
      </v-menu>

      <v-data-table
          :headers="headers"
          :items="medRecords"         
          :loading="mediaRecordState.isLoading"
          dense
          :height="height"
          disable-pagination
          hide-default-footer
      >
        <template v-slot:item="{ item }">
          
          <tr @click="typeof item.medRecordTemplate !== 'undefined' ? medRecordId=item.id : mediaId=item.id">
            <td class="text-no-wrap">{{item.id}}</td>
            <td>{{item.name!=null&&item.name!='' ? item.name : typeof item.medRecordTemplate !== 'undefined' ? item.medRecordTemplate.name : '-'}} <v-icon v-if="(typeof item.medRecordTemplate === 'undefined')" small class="ml-1" title="Документ">mdi-file</v-icon></td>
            <td>{{item.comment}}</td>
            <td class="text-no-wrap">
              <div v-if="misDesktopOnline&&typeof item.medRecordTemplate === 'undefined'">
                <v-btn small depressed @click.stop="misDesktopOpenFile(item)"><v-icon class="mr-2" small>mdi mdi-open-in-new</v-icon>Открыть локально</v-btn>
              </div>
            </td>
            <td>
              <v-tooltip v-if="item.status==='unactive'" bottom>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon v-bind="attrs" v-on="on" small class="mr-2">mdi-file-alert</v-icon>
                </template>
                <span>Черновик</span>
              </v-tooltip>
            </td>
            <td class="text-no-wrap">
              <v-avatar size="32" class="mr-2" v-if="typeof item.userAuthor.imageUrl !== 'undefined'&&item.userAuthor.imageUrl!==null">
                <img :src="item.userAuthor.imageUrl"/>
              </v-avatar>
              {{item.userAuthor.name}}
            </td>
            <td class="text-no-wrap">{{$tools.dateTime(item.created)}}</td>
            <td class="text-no-wrap">
              <v-chip
                v-if="item.isSent"
                class="font-weight-medium"
                color="green"
                text-color="white"
                small
              >
                Отправлено
              </v-chip>
            </td>
          </tr>
        </template>
      </v-data-table>
    </v-card>
    <med-record-update :med-record-id.sync="medRecordId" @medRecordCopied="medRecordCopied" :userId="$router.currentRoute.params.id"/>

    <media-view :media-id.sync="mediaId" :user-id="$router.currentRoute.params.id" :userName="userName"/>
    <uploader
        :dialog.sync="isUploadOpen"
        :multiple="true"
        :url="'/api/media/media/upload/?mediaDirId=2&userId='+$router.currentRoute.params.id"
        @uploadComplete="uploadComplete"
    />
  </div>
</template>

<script>
import State from "@/plugins/state";
import Api from "@/Api";
import MedRecordUpdate from "@/views/MedRecord/MedRecordUpdate";
import MediaView from "@/views/Media/MediaView";
import Uploader from "@/components/Uploader";
import axios from "axios";

export default {
  components: {MedRecordUpdate, MediaView, Uploader},
  props:['userName'],
  data: () => ({
    categorysState: new State(),
    categorys: [],
    headers: [
      { text: 'ID', value: 'id', sortable: true, class:'text-no-wrap', width:'auto'},
      { text: 'Название', value: 'medRecordTemplate.name', sortable: true, class:'text-no-wrap'},
      { text: 'Комментарий', sortable: false},
      { text: '', sortable: false},
      { text: '', sortable: false},
      { text: 'Автор', value: 'userAuthor', sortable: false, class:'text-no-wrap'},
      { text: 'Дата создания', value: 'created', sortable: true, class:'text-no-wrap'},
      { text: 'Отправка на Email', sortable: false},
    ],
    mediaRecordState: new State(),
    mediaRecordCreateState: new State(),
    medRecords:[],
    medRecordId:null,
    medias: [],
    mediaId: null,
    isUploadOpen: false,
    height:null,

    mediaTemplateCategories: [],
    mediaCreateState: new State(),
    mediaCreateErrorState: new State(),

    mediaTemplates: [],
    misDesktopOnline:false,
  }),
  mounted() {
    this.height = window.innerHeight - 200-12;
    this.loadTemplatesCategories();
    this.$tools.misDesktopStatus((v)=>{
      this.misDesktopOnline = v;
    });
  },
  watch: {
    medRecordId(){
      if (this.medRecordId===null)
      this.mediaRecordLoad();
    }
  },
  methods:{
    loadTemplatesCategories(){
      this.categorysState.stateLoading();
      Api.service.medRecord.medRecordTemplateCategorys(1, 1000,  false,(v)=>{

        let categorys = [];
        v.data.forEach((v)=>{
          v._show = false;
          if (v.medRecordTemplateCategorys.length!==0)
            v._show = true;
          categorys.push(v);
        })
        this.categorys = categorys;
        this.mediaRecordLoad();
      }, ()=>{
        this.categorysState.stateError();
      });

      Api.service.media.mediaTemplateCategory(2,(v)=>{
        this.mediaTemplates = [];
        let mediaCategories = [];
        v.data[0].mediaTemplateCategorys.forEach((v)=>{
          v._show = false;
          if (v.subcategories.length!==0||v.mediaTemplates.length!==0)
            v._show = true;
            mediaCategories.push(v);
        })
        this.mediaTemplateCategories = mediaCategories;
        
        if (typeof v.data[0].mediaTemplates!=undefined&&v.data[0].mediaTemplates.length>0)
          this.mediaTemplates = Object.values(v.data[0].mediaTemplates)

        this.categorysState.stateSuccess();
      }, ()=>{
        this.categorysState.stateError();
      });

    },
    mediaRecordLoad(){
      this.mediaRecordState.stateLoading();
      Api.service.medRecord.medRecords(this.$router.currentRoute.params.id, 1, 10000, (datas)=>{

        //+ medias
        Api.service.media.medias(2, this.$router.currentRoute.params.id, 1, 10000, (medias)=>{
          let medRecordsAndMedia = datas.data.concat(medias.data);

          //+ laboratory tests
          Api.service.media.medias(3, this.$router.currentRoute.params.id, 1, 10000, (medias2)=>{
            medRecordsAndMedia = medRecordsAndMedia.concat(medias2.data);
            //sort by created
            let sortedArray = medRecordsAndMedia.slice().sort(function(a, b) {
              return new Date(b.created) - new Date(a.created);
            });
            this.medRecords = sortedArray;

            this.mediaRecordState.stateSuccess();
          });
          
        });
      
      }, ()=>{
        this.mediaRecordState.stateError();
      });
    },
    medRecordCreate(medRecordTemplateId){
      this.mediaRecordCreateState.stateLoading();
      Api.service.medRecord.medRecordCreate(medRecordTemplateId, this.$router.currentRoute.params.id, (data)=>{
        this.mediaRecordCreateState.stateSuccess();
        this.medRecordId = data.id;
        this.loadTemplatesCategories();
      }, ()=>{
        this.mediaRecordCreateState.stateError();
      });
    },
    medRecordCopied(id){
      this.loadTemplatesCategories();
      this.medRecordId = id;
    },
    uploadComplete(){
      this.loadTemplatesCategories();
    },

    //media
    // loadMediaTemplates(mediaDirId){
    //   this.mediaTemplates = [];
    //   this.mediaTemplatesState.stateLoading();
    //   Api.service.media.mediaTemplates(mediaDirId,(v)=>{
    //     console.log(123)
    //     console.log(v)
    //     this.mediaTemplatesState.stateSuccess();
    //     this.mediaTemplates = v;
    //   }, ()=>{
    //     this.mediaTemplatesState.stateError();
    //   });
    // },

    mediaCreateAction(mediaTemplateId){
      this.mediaCreateState.stateLoading();
      axios.post(Api.host+'/media/mediaTemplate/'+mediaTemplateId+'/mediaCreate/', {
        userId:this.$router.currentRoute.params.id
      })
          .then(r=>{
            this.mediaCreateState.stateSuccess();
            this.loadTemplatesCategories();
            this.$tools.misDesktopStatus((v)=>{
              if(v){
                this.$tools.misDesktopOpenFile(
                    r.data.fileName,
                    r.data.name + r.data.fileName.substring(r.data.fileName.lastIndexOf("."), r.data.fileName.length),
                    "https://"+window.location.hostname+"/api/media/media/upload/desktop/",
                    {id: r.data.id},
                    ()=>{
                      this.$store.commit('alertSuccess', 'Файл открывается локально');
                    }, ()=>{
                      this.$store.commit('alertError', 'Не удалось открыть файл локально');
                    }
                );
              } else {
                this.mediaId = r.data.id;
              }
            });
          })
          .catch((e)=>{
            console.log(e);
            this.mediaCreateState.stateError();
          });
    },
    misDesktopOpenFile(v){
      axios.get(Api.host + '/media/media/' + v.id + '/').then(r1 => {
        let mediaData = r1.data;
        this.$tools.misDesktopOpenFile(
            mediaData.filename,
            mediaData.name + mediaData.filename.substring(mediaData.filename.lastIndexOf("."), mediaData.length),
            "https://"+window.location.hostname+"/api/media/media/upload/desktop/",
            {
              id: v.id,
            },
            ()=>{
              this.$store.commit('alertSuccess', 'Файл открывается локально');
            }, ()=>{
              this.$store.commit('alertError', 'Не удалось открыть файл локально');
            }
        );
      });
    }
  }
}
</script>
