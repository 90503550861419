<template>
  <div class="pa-6">
    <uploader
        v-if="mediaDirs.length>0"
        :dialog.sync="isUploadOpen"
        :multiple="true"
        :url="'/api/media/media/upload/?mediaDirId='+mediaDirs[tabIndex].id+'&userId='+$router.currentRoute.params.id"
        @uploadComplete="uploadComplete"
    />
    <v-card elevation="2" class="w-100" :loading="mediaDirsState.isLoading">
      <v-tabs v-model="tabIndex">
        <v-tab v-for="(mediaDir, key) in mediaDirs" :key="key">{{ mediaDir.name }}</v-tab>
        <v-tab-item v-for="(mediaDir, key) in mediaDirs" :key="key">
          <div v-if="mediaDir.des!=null" class="px-4 pt-2 pb-1 body-2">{{ mediaDir.des }}</div>
          <v-btn  class="ml-4 my-2 mr-2" depressed color="primary" @click="isUploadOpen = true">
            <span>Загрузить</span>
            <v-icon class="ml-2" small>mdi-cloud-upload-outline</v-icon>
          </v-btn>

          <v-menu offset-y :close-on-content-click="false">
            <template v-slot:activator="{ on, attrs }">
              <v-btn v-bind="attrs" v-on="on" class="ml-2 my-2" depressed color="primary" :loading="categorysState.isLoading || mediaCreateState.isLoading">
                <span>Создать</span>
                <v-icon class="ml-2" small>mdi-plus-circle-outline</v-icon>
              </v-btn>
            </template>
            <v-sheet max-width="750">
              <v-expansion-panels accordion>
                <v-expansion-panel v-for="category in mediaTemplateCategories" :key="'categorys_'+category.id" :class="category._show ? '' : 'd-none'">
                  <v-expansion-panel-header class="font-weight-medium">{{ category.name }}</v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <div v-if="category.subcategories.length===0&&category.mediaTemplates.length===0" class="text-center caption pa-1">Не найдено</div>
                    <v-expansion-panels accordion class="expansionPanelContentExpanded">
                      <v-expansion-panel v-for="subcategory in category.subcategories" :key="'categorys1_'+subcategory.id">
                        <v-expansion-panel-header class="font-weight-medium">{{ subcategory.name }}</v-expansion-panel-header>
                        <v-expansion-panel-content>
                          <v-list>
                            <v-list-item v-for="mediaTemplate in subcategory.mediaTemplates" :key="'mediaTemplate_'+mediaTemplate.id" @click="mediaCreateAction(mediaTemplate.id)">
                              <v-list-item-title class="body-2 pl-2">{{ mediaTemplate.name }}</v-list-item-title>
                            </v-list-item>
                            <div v-if="typeof subcategory.mediaTemplates !=='undefined'&&subcategory.mediaTemplates.length===0" class="text-center caption pa-1">Не найдено</div>
                          </v-list>
                        </v-expansion-panel-content>

                      </v-expansion-panel>
                    </v-expansion-panels>
                       <v-list v-if="category.mediaTemplates.length!==0">
                          <v-list-item v-for="mediaTemplate in category.mediaTemplates" :key="'mediaTemplate_'+mediaTemplate.id" @click="mediaCreateAction(mediaTemplate.id)">
                            <v-list-item-title class="body-2 pl-2">{{ mediaTemplate.name }}</v-list-item-title>
                          </v-list-item>
                        </v-list>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
              <v-list v-if="mediaTemplates.length>0">
                <v-list-item v-for="mediaTemplate in mediaTemplates" :key="'mediaTemplate_'+mediaTemplate.id" @click="mediaCreateAction(mediaTemplate.id)">
                  <v-list-item-title class="body-2 pl-2">{{ mediaTemplate.name }}</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-sheet>
          </v-menu>

          <v-btn
              v-if="mediaDir.id===4"
              @click="nvState.modalOpen({
                year:nvYearNow(),
                comBranchId:null,
                amount:null,
                taxpayer:{
                  lastName:null,
                  firstName:null,
                  secondName:null,
                  birth:null,
                  inn:null,
                  passType:null,
                  pasSeriesNumbers:null,
                  pasDate:null,
                }
              });nvInit();nvAmount()"
              class="ml-4"
              depressed
              color="primary"
          >
            Налоговый вычет
          </v-btn>
          <v-data-table
              :headers="headers"
              :items="medias.data"         
              :loading="mediasState.isLoading"
              :options.sync="options"
              :server-items-length="total"
              :footer-props="{
                'items-per-page-options': [25, 50, 100]
              }"
              :items-per-page="25"
              dense
              :height="height"
          >
            <template v-slot:item="{ item }">
              <tr @click="mediaId = item.id">
                <td class="text-no-wrap">{{item.id}}</td>
                <td class="text-no-wrap">{{item.name}}</td>
                <td class="text-no-wrap">
                  <div v-if="misDesktopOnline">
                    <v-btn small depressed @click.stop="misDesktopOpenFile(item)"><v-icon class="mr-2" small>mdi mdi-open-in-new</v-icon>Открыть локально</v-btn>
                  </div>
                </td>
                <td>
                  <v-tooltip v-if="item.status==='unactive'" bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon v-bind="attrs" v-on="on" small class="mr-2">mdi-file-alert</v-icon>
                    </template>
                    <span>Черновик</span>
                  </v-tooltip>
                </td>
                <td class="text-no-wrap">{{item.userAuthor.name}}</td>
                <td class="text-no-wrap">{{$tools.dateTime(item.created)}}</td>
                <td class="text-no-wrap">
                  <v-chip
                    v-if="item.isSent"
                    class="font-weight-medium"
                    color="green"
                    text-color="white"
                    small
                  >
                    Отправлено
                  </v-chip>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-tab-item>
      </v-tabs>
    </v-card>
    <media-view :media-id.sync="mediaId" :user-id="$router.currentRoute.params.id" :userName="userName"/>
    
    <dialog-view :open="mediaCreateErrorState.isModalOpen" title="Ошибка создания документа" @cancel="mediaCreateErrorState.modalClose()">
      <template v-slot:content>
        <div v-if="mediaCreateErrorState.isModalOpen" v-html="mediaCreateErrorState.modalData.replace(/([^>])\n/g, '$1<br/>')"></div>
      </template>
    </dialog-view>

    <dialog-view
        title="Налоговый вычет"
        :open="nvState.isModalOpen"
        :loading="currentClient==null"
        @cancel="nvState.modalClose()"
        width="700"
    >
      <template v-slot:content>
        <v-form ref="nvForm" v-if="currentClient!==null && nvState.modalData!==null">
          <v-row class="rowSmall">
            <v-col cols="3">
              <v-select
                  label="Год"
                  v-model="nvState.modalData.year"
                  :items="nvYears()"
                  item-text="name"
                  outlined dense hide-details="auto" class="mb-4"
              />
            </v-col>
            <v-col cols="6">
              <com-branch-autocomplete-single :select.sync="nvState.modalData.comBranch"/>
            </v-col>
            <v-col cols="3">
              <v-text-field
                  v-model="nvState.modalData.amount"
                  label="Сумма"
                  outlined dense hide-details="auto"
              >
                <template v-slot:append>
                  <v-progress-circular v-if="nvAmountState.isLoading" indeterminate class="mt-1" color="primary" size="16" width="2"/>
                  <v-btn v-else @click="nvAmount()" icon style="margin-top:-6px;margin-right:-10px"><v-icon size="18">mdi-refresh</v-icon></v-btn>
                </template>
              </v-text-field>
            </v-col>
          </v-row>

          <sheet-group class="mb-4">
            <div class="body-2 mb-4">Пациент</div>
            <recom name="Данные пациента можно изменить в профиле пользователя" class="mb-4"/>
            <v-row class="rowSmall mb-2">
              <v-col cols="6">
                <v-text-field :value="currentClient.lastName" label="Фамилия" outlined dense hide-details="auto" readonly />
              </v-col>
              <v-col cols="6">
                <v-text-field :value="currentClient.firstName" label="Имя" outlined dense hide-details="auto" readonly />
              </v-col>
              <v-col cols="6">
                <v-text-field :value="currentClient.secondName" label="Отчество" outlined dense hide-details="auto" readonly />
              </v-col>
              <v-col cols="6">
                <v-text-field :value="$tools.date(currentClient.userProfile.birth)" label="Дата рождения" outlined dense hide-details="auto" readonly/>
              </v-col>
            </v-row>

          </sheet-group>
          <sheet-group class="fill-height">
            <div class="body-2 mb-4">Плательщик</div>
            <recom name="Если плательщик и пациент один и тот же человек, оставьте данные плательщика незаполненными" class="mb-4"/>
            <recom
                v-if="taxPayerRecoms.length>0"
                name="Возможно следующие пользователи"
                class="mb-4"
            >
              <v-list class="pa-0" dense color="transparent">
                <v-list-item v-for="v in taxPayerRecoms" :key="v.id" @click="nvTaxPayerLoad(v)">
                  <v-list-item-title class="py-2">
                    {{v.lastName}} {{v.firstName}} {{v.secondName}}<dot/>{{$tools.years(v.userProfile.birth)}}<dot/>{{$tools.date(v.userProfile.birth)}}
                  </v-list-item-title>
                  <v-list-item-action>
                    <v-btn icon @click.stop="$tools.openUrl('../../'+v.type+'/'+v.id+'/', true)" class="mt-n2">
                      <v-icon small>mdi-open-in-new</v-icon>
                    </v-btn>
                  </v-list-item-action>
                </v-list-item>
              </v-list>
            </recom>
            <v-row class="rowSmall mb-2">
              <v-col cols="6">
                <v-text-field v-model="nvState.modalData.taxpayer.lastName" label="Фамилия" outlined dense hide-details="auto" clearable />
              </v-col>
              <v-col cols="6">
                <v-text-field v-model="nvState.modalData.taxpayer.firstName" label="Имя" outlined dense hide-details="auto" clearable />
              </v-col>
              <v-col cols="6">
                <v-text-field v-model="nvState.modalData.taxpayer.secondName" label="Отчество" outlined dense hide-details="auto" clearable />
              </v-col>
              <v-col cols="6">
                <v-text-field v-model="nvState.modalData.taxpayer.birth" label="Дата рождения" hint="В формате: 10.10.2020" outlined dense hide-details="auto" clearable/>
              </v-col>
              <v-col cols="6">
                <v-text-field v-model="nvState.modalData.taxpayer.inn" label="ИНН" outlined dense hide-details="auto" clearable />
              </v-col>
              <v-col cols="6">
                <autocomplete-single name="Тип документа" :items="passTypes" :select.sync="nvState.modalData.taxpayer.passType" class="mb-0" />
              </v-col>
              <v-col cols="6">
                <v-text-field v-model="nvState.modalData.taxpayer.pasSeriesNumbers" label="Номер и серия документа" outlined dense hide-details="auto" clearable />
              </v-col>
              <v-col cols="6">
                <v-text-field v-model="nvState.modalData.taxpayer.pasDate" label="Дата выдачи документа" hint="В формате: 10.10.2020" outlined dense hide-details="auto" clearable/>
              </v-col>
            </v-row>
          </sheet-group>
        </v-form>
      </template>
      <template v-slot:actions>
        <v-btn
            text color="primary"
            @click="nvCreate()"
            :loading="nvCreateState.isLoading"
            :disabled="nvCreateState.isLoading || nvAmountState.isLoading || currentClient===null"
        >
          ОК
        </v-btn>
      </template>
    </dialog-view>
  </div>
</template>

<script>
import State from "@/plugins/state";
import Api from "@/Api";
import MediaView from "@/views/Media/MediaView";
import Uploader from "@/components/Uploader";
import DialogView from "@/components/DialogView";
import axios from "axios";
import moment from "moment/moment";
import SheetGroup from "@/componentsV2/base/SheetGroup.vue";
import {
  Entity_PassType,
  Entity_User,
  Entity_UserProfile
} from "../../../../EntityStoreCacheService";
import Recom from "@/componentsV2/base/Recom.vue";
import Dot from "@/componentsV2/base/Dot.vue";
import AutocompleteSingle from "@/componentsV2/base/AutocompleteSingle.vue";
import ComBranchAutocompleteSingle from "@/componentsV2/custom/ComBranchAutocompleteSingle.vue";

export default {
  components:{ComBranchAutocompleteSingle, AutocompleteSingle, Dot, Recom, SheetGroup, DialogView, Uploader, MediaView},
  props:['userName'],
  data: () => ({
    mediaDirsState: new State(),
    mediaDirs: [],
    tabIndex:0,
    options:{},
    mediasState: new State(),
    medias: [],
    headers: [
      { text: 'ID', value: 'id', class:'text-no-wrap', width:'auto'},
      { text: 'Название', value: 'name', class:'text-no-wrap'},
      { text: '', sortable: false},
      { text: '', sortable: false},
      { text: 'Автор', value: 'userAuthor', class:'text-no-wrap'},
      { text: 'Дата создания', value: 'created', class:'text-no-wrap'},
      { text: 'Отправка на Email', sortable: false},
    ],
    total:0,
    mediaId: null,

    mediaCreateState: new State(),
    mediaCreateErrorState: new State(),

    mediaTemplates: [],

    isUploadOpen: false,

    misDesktopOnline:false,
    height:null,

    categorysState: new State(),
    mediaTemplateCategories: [],

    nvCreateState: new State(),
    currentClient:null,
    taxPayerRecoms:[],
    passTypes:[],
    nvAmountState: new State(),
    nvState: new State(),
  }),
  mounted() {
    this.height = window.innerHeight - 240-48-20;
    this.loadMediaDirs();
    this.$tools.misDesktopStatus((v)=>{
      this.misDesktopOnline = v;
    });
    this.loadMediasCategories(2)
  },
  watch:{
    options: {
      handler () {
        if (this.mediasState.isSuccess) {
          this.loadMedias();
        }
      },
      deep: true,
    },
    tabIndex(index){
      this.loadMedias(this.mediaDirs[index].id);
      this.loadMediasCategories(this.mediaDirs[index].id)
    }
  },
  methods:{
    loadMediaDirs(){
      this.mediaDirsState.stateLoading();
      Api.service.media.mediaDirs(1, 25, (v)=>{
        this.mediaDirsState.stateSuccess();
        this.mediaDirs = v;
        this.loadMedias(this.mediaDirs[0].id);
      }, ()=>{
        this.mediaDirsState.stateError();
      });
    },

    loadMedias(){
      this.medias = [];
      this.mediasState.stateLoading();
      Api.service.media.medias(this.mediaDirs[this.tabIndex].id, this.$router.currentRoute.params.id, this.options.page, this.options.itemsPerPage, (v)=>{
        this.total = v.total

        this.medias = v;
        
        this.mediasState.stateSuccess();
      }, ()=>{
        this.mediasState.stateError();
      });
    },

    loadMediasCategories(id){
      this.categorysState.stateLoading();
      Api.service.media.mediaTemplateCategory(id,(v)=>{
        this.mediaTemplates = [];
        let mediaCategories = [];
        v.data[0].mediaTemplateCategorys.forEach((v)=>{
          v._show = false;
          if (v.subcategories.length!==0||v.mediaTemplates.length!==0)
            v._show = true;
            mediaCategories.push(v);
        })
        this.mediaTemplateCategories = mediaCategories;
        if (typeof v.data[0].mediaTemplates!=undefined&&v.data[0].mediaTemplates.length>0)
         this.mediaTemplates = Object.values(v.data[0].mediaTemplates)

        this.categorysState.stateSuccess();
        }, ()=>{
          this.categorysState.stateError();
        });
    },

    mediaCreateAction(mediaTemplateId){
      this.mediaCreateState.stateLoading();
      axios.post(Api.host+'/media/mediaTemplate/'+mediaTemplateId+'/mediaCreate/', {
        userId:this.$router.currentRoute.params.id
      })
          .then(r=>{
            this.mediaCreateState.stateSuccess();
            this.loadMedias();
            this.$tools.misDesktopStatus((v)=>{
              if(v){
                this.$tools.misDesktopOpenFile(
                    r.data.fileName,
                    r.data.name + r.data.fileName.substring(r.data.fileName.lastIndexOf("."), r.data.fileName.length),
                    "https://"+window.location.hostname+"/api/media/media/upload/desktop/",
                    {id: r.data.id},
                    ()=>{
                      this.$store.commit('alertSuccess', 'Файл открывается локально');
                    }, ()=>{
                      this.$store.commit('alertError', 'Не удалось открыть файл локально');
                    }
                );
              } else {
                this.mediaId = r.data.id;
              }
            });
          })
          .catch(()=>{
            this.mediaCreateState.stateError();
          });
    },

    uploadComplete(){
      this.loadMedias();
    },

    misDesktopOpenFile(v){
      axios.get(Api.host + '/media/media/' + v.id + '/').then(r1 => {
        let mediaData = r1.data;
        this.$tools.misDesktopOpenFile(
            mediaData.filename,
            mediaData.name + mediaData.filename.substring(mediaData.filename.lastIndexOf("."), mediaData.length),
            "https://"+window.location.hostname+"/api/media/media/upload/desktop/",
            {
              id: v.id,
            },
            ()=>{
              this.$store.commit('alertSuccess', 'Файл открывается локально');
            }, ()=>{
              this.$store.commit('alertError', 'Не удалось открыть файл локально');
            }
        );
      });
    },

    nvYearNow(){
      return moment().format('YYYY')
    },
    nvYears(){
      let now = this.nvYearNow();
      return [
        {name:now,value:now},
        {name:now-1,value:now-1},
        {name:now-2,value:now-2},
      ]
    },

    nvInit(){
      new Entity_User()
          .selects(Entity_User.id)
          .selects(Entity_User.firstName)
          .selects(Entity_User.lastName)
          .selects(Entity_User.secondName)
          .selects(Entity_User.phone)
          .selects([Entity_User.userProfile, Entity_UserProfile.birth].join('.'))
          .selects([Entity_User.userProfile, Entity_UserProfile.phone1].join('.'))
          .filterAnd([Entity_User.entity, Entity_User.id].join('.')+'=?', this.$router.currentRoute.params.id)
          .addStateCallback((v)=>{
            if(v.success) {
              this.currentClient = v.items[0];
              new Entity_User()
                  .selects(Entity_User.id)
                  .selects(Entity_User.firstName)
                  .selects(Entity_User.lastName)
                  .selects(Entity_User.secondName)
                  .selects(Entity_User.type)
                  .selects([Entity_User.userProfile, Entity_UserProfile.birth].join('.'))
                  .selects([Entity_User.userProfile, Entity_UserProfile.inn].join('.'))
                  .selects([Entity_User.userProfile, Entity_UserProfile.passType, Entity_PassType.id].join('.'))
                  .selects([Entity_User.userProfile, Entity_UserProfile.pasSeries].join('.'))
                  .selects([Entity_User.userProfile, Entity_UserProfile.pasNumber].join('.'))
                  .selects([Entity_User.userProfile, Entity_UserProfile.pasDate].join('.'))

                  .filterAnd([Entity_User.entity, Entity_User.id].join('.')+'!=?', this.currentClient.id)
                  .filterAnd([Entity_User.entity, Entity_User.userProfile, Entity_UserProfile.birth].join('.')+'<?', moment().subtract(18, 'years').format('YYYY-MM-DD'))
                  .filterAnd(
                      [Entity_User.entity, Entity_User.phone].join('.')+'=? OR '+
                      [Entity_User.entity, Entity_User.userProfile, Entity_UserProfile.phone1].join('.')+'=? OR '+
                      [Entity_User.entity, Entity_User.phone].join('.')+'=? OR '+
                      [Entity_User.entity, Entity_User.userProfile, Entity_UserProfile.phone1].join('.')+'=?'
                      ,
                      this.currentClient.phone,
                      this.currentClient.phone,
                      this.currentClient.phone ?? this.currentClient.userProfile.phone1,
                      this.currentClient.phone ?? this.currentClient.userProfile.phone1,
                  )
                  .addStateCallback((v)=>{
                    if(v.success) this.taxPayerRecoms = v.items;
                  })
                  .request();
            }
          })
          .request();
      new Entity_PassType()
          .selects(Entity_PassType.id)
          .selects(Entity_PassType.name)
          .selects(Entity_PassType.code)
          .order(Entity_PassType.sort, true)
          .page(1)
          .onPage(100)
          .addStateCallback((v)=>{
            if(v.success)
              this.passTypes = v.items;
          })
          .request()
    },

    nvTaxPayerLoad(v){
      this.nvState.modalData.taxpayer.firstName = v.firstName;
      this.nvState.modalData.taxpayer.lastName = v.lastName;
      this.nvState.modalData.taxpayer.secondName = v.secondName;
      this.nvState.modalData.taxpayer.birth = this.$tools.dateTimeFormat(v.userProfile.birth, 'DD.MM.YYYY');
      this.nvState.modalData.taxpayer.inn = v.userProfile.inn;
      this.nvState.modalData.taxpayer.passType = v.userProfile.passType;
      this.nvState.modalData.taxpayer.pasSeriesNumbers = v.userProfile.pasSeries ?? '';
      this.nvState.modalData.taxpayer.pasSeriesNumbers += v.userProfile.pasNumber ?? '';
      this.nvState.modalData.taxpayer.pasDate = this.$tools.dateTimeFormat(v.userProfile.pasDate, 'DD.MM.YYYY');
    },

    nvAmount(){
      this.nvAmountState.stateLoading();

      let data = this.$tools.copy(this.nvState.modalData)
      data.userIdPatient = this.$router.currentRoute.params.id;
      data.taxpayer.birth = this.$tools.isEmpty(data.taxpayer.birth) ? null : this.$tools.dateTimeFormat(data.taxpayer.birth, 'YYYY-MM-DD', 'DD.MM.YYYY')
      data.taxpayer.pasDate = this.$tools.isEmpty(data.taxpayer.pasDate) ? null :  this.$tools.dateTimeFormat(data.taxpayer.pasDate, 'YYYY-MM-DD', 'DD.MM.YYYY')
      data.comBranchId = this.$tools.isEmpty(data.comBranch) ? null :  data.comBranch.id
      delete data.comBranch;

      axios.post(Api.host+'/analytic/sreport/itc/amount/', data)
          .then((r) =>{
            this.nvState.modalData.amount = r.data.amount;
            this.nvAmountState.stateSuccess();
          })
          .catch(() =>{
            this.nvAmountState.stateError();
          });
    },

    nvCreate(){
      if (this.$refs.nvForm.validate()) {
        this.nvCreateState.stateLoading();

        let data = this.$tools.copy(this.nvState.modalData)
        data.userIdPatient = this.$router.currentRoute.params.id;
        data.taxpayer.birth = this.$tools.isEmpty(data.taxpayer.birth) ? null : this.$tools.dateTimeFormat(data.taxpayer.birth, 'YYYY-MM-DD', 'DD.MM.YYYY')
        data.taxpayer.pasDate = this.$tools.isEmpty(data.taxpayer.pasDate) ? null :  this.$tools.dateTimeFormat(data.taxpayer.pasDate, 'YYYY-MM-DD', 'DD.MM.YYYY')
        data.comBranchId = this.$tools.isEmpty(data.comBranch) ? null :  data.comBranch.id
        delete data.comBranch;

        axios.post(Api.host+'/analytic/sreport/itc/', data)
            .then(() =>{
              this.nvCreateState.stateSuccess();
              this.nvState.modalClose()
              this.loadMedias();
            })
            .catch(() =>{
              this.nvCreateState.stateError();
            });
      }
    }
  }
}
</script>