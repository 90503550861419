<template>
  <td :rowspan="rowspan" :colspan="colspan" :style="'text-align:'+align">
    <div
        v-for="(fieldValue, index) in fieldValues"
        :key="index"
        class="d-inline"
    >
      <div v-if="typeof fieldValue.text!=='undefined'" class="d-inline" v-html="fieldValue.text"></div>
      <div v-else class="d-inline medRecordValueView">
        <med-record-update-value-number-type v-if="fieldValue.type==='number'" :editor-field.sync="fieldValue"/>
        <med-record-update-value-text-type v-if="fieldValue.type==='text'" :editor-field.sync="fieldValue"/>
        <med-record-update-value-date-type v-if="fieldValue.type==='date'" :editor-field.sync="fieldValue"/>
        <med-record-update-value-time-type v-if="fieldValue.type==='time'" :editor-field.sync="fieldValue"/>
        <med-record-update-value-file-type v-if="fieldValue.type==='file'" :editor-field.sync="fieldValue"/>
        <med-record-update-value-icd-type v-if="fieldValue.type==='icd'" :editor-field.sync="fieldValue"/>
      </div>
    </div>
  </td>
</template>

<script>
import MedRecordUpdateValueNumberType from "@/views/MedRecord/MedRecordUpdateValueNumberType";
import MedRecordUpdateValueTextType from "@/views/MedRecord/MedRecordUpdateValueTextType";
import MedRecordUpdateValueDateType from "@/views/MedRecord/MedRecordUpdateValueDateType";
import MedRecordUpdateValueTimeType from "@/views/MedRecord/MedRecordUpdateValueTimeType";
import MedRecordUpdateValueFileType from "@/views/MedRecord/MedRecordUpdateValueFileType";
import MedRecordUpdateValueIcdType from "@/views/MedRecord/MedRecordUpdateValueIcdType";
export default {
  components: {
    MedRecordUpdateValueFileType,
    MedRecordUpdateValueTimeType,
    MedRecordUpdateValueDateType,
    MedRecordUpdateValueTextType,
    MedRecordUpdateValueNumberType,
    MedRecordUpdateValueIcdType
  },
  props:['body', 'medRecordTemplateFieldValues', 'index'],
  data: () => ({
    bodyLocal:'',
    rowspan:1,
    colspan:1,
    align:'left',
    fieldValues:[],
  }),
  watch: {
    body: {
      immediate: true,
      handler () {
        this.bodyLocal = this.body;
        this.fieldRender();
      }
    }
  },
  methods:{
    fieldRender(){

      let bodyAttrs = this.bodyLocal.match(/<col([\s\S]*?)>/)[0];

      let bodyRowspan = bodyAttrs.match(/rowspan="(.*)"/);
      if (bodyRowspan!=null)
        this.rowspan = bodyRowspan[1];

      let bodyColspan = bodyAttrs.match(/colspan="(.*)"/);
      if (bodyColspan!=null)
        this.colspan = bodyColspan[1];

      let bodyAlign = bodyAttrs.match(/align="(.*)"/);
      if (bodyAlign!=null)
        this.align = bodyAlign[1];

      //COMPONENTS
      let fields = [];
      let strOriginal = this.bodyLocal.match(/<col.*>([\s\S]*?)<\/col>/)[1];

      let str = strOriginal;
      let tagStart ='{{';
      let tagEnd ='}}';
      let tagStartFound = 0;
      let tagEndFound = 0;

      let isWork = true;
      while(isWork){
        tagStartFound = str.indexOf(tagStart);
        if (tagStartFound===-1){
          isWork = false;
        } else {
          if (tagStartFound>0){
            let s = strOriginal.length-str.length;
            let e = (strOriginal.length-str.length)+tagStartFound;
            if (e>s)
              fields.push({
                text:strOriginal.substring(s, e).replaceAll(/(?:\r\n|\r|\n)/g, '<br>')
              });
          }

          str = str.substring(tagStartFound+tagStart.length, str.length);
          tagEndFound = str.indexOf(tagEnd);
          let tagBody = str.substring(0, tagEndFound);
          let tagId = tagBody.substring(0, tagBody.indexOf(':'));
          let tagValue = tagBody.substring(tagBody.indexOf(':')+1, tagBody.length);

          this.medRecordTemplateFieldValues.forEach((v2)=> {
            if (v2.id+''===tagId){
              let options = [];
              v2.medRecordTemplateFieldValueOptions.forEach((v3)=> {
                options.push({
                  id:v3.id,
                  value:v3.value,
                  sort:v3.sort
                });
              });
              fields.push({
                id:v2.id,
                type:v2.type,
                value:v2.value,
                options:options,
                currentValue:tagValue,
                customValue:'',
              });
            }
          });
          str = str.substring(tagEndFound+tagEnd.length, str.length);
        }
      }

      let s = strOriginal.length-str.length;
      let e = strOriginal.length;
      if (e>s)
        fields.push({
          text:strOriginal.substring(s, e).replaceAll(/(?:\r\n|\r|\n)/g, '<br>')
        });

      this.fieldValues = fields;
    },

    fieldUpdateSelect(editorField, option){
      editorField.currentValue = option.value;
      this.fieldUpdatesToMedRecordValueValue();
    },

    fieldUpdateSelectCustom(editorField){
      editorField.currentValue = editorField.customValue;
      this.fieldUpdatesToMedRecordValueValue();
    },

    fieldUpdateRemove(editorField){
      editorField.remove=true;
      this.fieldUpdatesToMedRecordValueValue();
    },
    fieldUpdateMore(editorField){
      editorField.append=true;
      this.fieldUpdatesToMedRecordValueValue();
    },

    fieldUpdatesToMedRecordValueValue(){
      let value = '';
      this.fieldValues.forEach((v)=>{
        if (typeof v.text!=='undefined')
          value += v.text.replaceAll('<br>', '\n');
        if (typeof v.table!=='undefined')
          value += v.table;
        if (typeof v.remove==='undefined'){
          if (typeof v.currentValue!=='undefined')
            value += '{{'+v.id+':'+v.currentValue+'}}';
          if (v.append){
            value += ', {{'+v.id+':'+v.currentValue+'}}';
          }
        } else {
          value += '{{removeComma}}';
        }
      });

      value = value.replace(', {{removeComma}}','');
      value = value.replace('{{removeComma}}, ','');
      value = value.replace('{{removeComma}}','');

      this.bodyLocal = '<col'+(this.rowspan!==1 ? ' rowspan="'+this.rowspan+'"' : '')+(this.colspan!==1 ? ' colspan="'+this.colspan+'"' : '')+'>'+value+'</col>';
      this.$emit("updated", {
        index:this.index,
        body:this.bodyLocal
      });
      this.fieldRender();
    },
  }

}
</script>

<style lang="scss" scoped>
td{
  border: 1px solid #aaa;
  padding: 0.5rem;
  user-select: text !important;
}
</style>