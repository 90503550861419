import { render, staticRenderFns } from "./MedRecordUpdateValueNumberType.vue?vue&type=template&id=3a51d0b2"
import script from "./MedRecordUpdateValueNumberType.vue?vue&type=script&lang=js"
export * from "./MedRecordUpdateValueNumberType.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports