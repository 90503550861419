<template>
  <div class="d-inline">
    <v-menu :close-on-content-click="false" v-model="isOpen" offset-y>
      <template v-slot:activator="{ on, attrs }">
        <div v-bind="attrs" v-on="on" class="d-inline blue--text text--darken-2">
          <span>{{editorField.currentValue}}</span>
          <v-icon v-if="false" color="blue" class="mt-n1">mdi-menu-down</v-icon>
        </div>
      </template>
      <v-list :class="editorField.options.length>1 ? 'pt-0 pb-2' : 'pa-0'">
        <v-sheet min-width="480">
          <div class="mb-2">
            <v-row no-gutters class="pt-2 px-2 mb-2 align-center justify-space-between">
              <v-col>
                <v-btn text small @click="$parent.fieldUpdateRemove(editorField);isOpen=false">
                  <v-icon dense class="mr-2">mdi-close</v-icon>Удалить значение
                </v-btn>
              </v-col>
              <v-col class="text-right">
                <v-btn text small @click="$parent.fieldUpdateMore(editorField);isOpen=false">
                  <v-icon dense class="mr-2">mdi-plus</v-icon>Дублировать значение
                </v-btn>
              </v-col>
              <v-col cols="auto">
                <calcs-menu @calc="(v)=>{editorField.customValue=v}" :userId="userId" :types="['text','number']"/>
              </v-col>
            </v-row>
            <v-divider/>
          </div>
          <v-textarea
              ref="text"
              v-model="editorField.customValue"
              type="text"
              @change="$parent.fieldUpdateSelectCustom(editorField)"
              placeholder="Введите свой вариант"
              append-icon="mdi-check"
              @click:append="$parent.fieldUpdateSelectCustom(editorField);isOpen=false"
              @keydown.enter="saveByEnter($event,editorField)"
              outlined dense hide-details="auto"
              auto-grow
              rows="1"
              class="medRecordEditorFieldTextField mx-2 mb-2"
          />
        </v-sheet>
        <div>
          <v-list-item
              v-for="(option, index2) in editorField.options"
              :key="index2"
              @click="$parent.fieldUpdateSelect(editorField, option);isOpen=false">
            <v-list-item-title>
              {{ option.length===0 ? '(Пусто)' : option.value }}
            </v-list-item-title>
          </v-list-item>
        </div>
      </v-list>
    </v-menu>
  </div>
</template>

<script>

import CalcsMenu from "@/views/MedRecord/calcs/CalcsMenu.vue";

export default {
  components:{CalcsMenu},
  props:['editorField', 'editorFieldOpen', 'userId'],
  data: () => ({
    isOpen:false,
  }),
  watch:{
    isOpen(){
      setTimeout(()=>{
        if (this.isOpen)
          this.$refs.text.$el.querySelector('textarea').focus();
      }, 100);
    }
  },
  methods: {
    editorFieldUpdate(v){
      console.log(v)
    },
    saveByEnter(event,editorField) {
      if (!event.shiftKey) {
        event.preventDefault();
        this.$parent.fieldUpdateSelectCustom(editorField);
        this.isOpen=false;
      }
    }
  }
}
</script>
